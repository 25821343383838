@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: "Inter", sans-serif;
}

@layer utilities {
  ::-webkit-scrollbar {
    width: 0.15rem;
  }
  ::-webkit-scrollbar-track {
    background-color: #f8f8f8;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #d9d9d9;
    outline: none;
    border-radius: 0.275rem;
    width: 0.2rem;
  }

  ::-webkit-scrollbar:horizontal {
    height: 0.375rem;
  }
}

.menu {
  list-type: none;
  margin: 0;
  padding: 0;

  li {
    line-height: 2.5em;
    padding: 0 1em;
    cursor: pointer;
    &.active,
    &:hover {
      background-color: rgba(0, 0, 0, 0.1);
    }
    &.active:hover {
      background-color: rgba(0, 0, 0, 0.2);
    }
  }
}
.scrollbar {
}

.react-datepicker-popper {
  z-index: 60 !important;
}

.boxmenuMenu {
  z-index: 60 !important;
}
.Toastify__progress-bar {
  transform-origin: right !important;
}
.modal-open {
  overflow: hidden;
}
.Toastify__toast-container {
  z-index:99999 !important;
}
.clickCommentArea:nth-child(1) .showCommentArea{
  left: 0;
}
.clickCommentArea:nth-child(2) .showCommentArea{
  left: 0;
}
.clickCommentArea:nth-child(3) .showCommentArea{
  left: 0;
}
.clickCommentArea:nth-child(4) .showCommentArea{
  right: 0;
}
.clickCommentArea:nth-child(5) .showCommentArea{
  right: 0;
}
.clickCommentArea:nth-child(6) .showCommentArea{
  right: 0;
}
.clickCommentArea:nth-child(7) .showCommentArea{
  right: 0;
}

.empClickArea:nth-child(1) .empCommentShowArea{
  left: 0;
}
.empClickArea:nth-child(2) .empCommentShowArea{
  left: 0;
}
.empClickArea:nth-child(3) .empCommentShowArea{
  left: 0;
}
.empClickArea:nth-child(4) .empCommentShowArea{
  right: 0;
}
.empClickArea:nth-child(5) .empCommentShowArea{
  right: 0;
}
.empClickArea:nth-child(6) .empCommentShowArea{
  right: 0;
}
.empClickArea:nth-child(7) .empCommentShowArea{
  right: 0;
}